<template>
	<div>
		<div class="ft20 cl-main ftw600">服务人员申请列表</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="申请姓名">
						<a-input v-model="search.name" placeholder="请输入申请姓名"></a-input>
					</a-form-item>

					<a-form-item label="客户">
						<a-input v-model="search.member" placeholder="请输入客户姓名/手机号"></a-input>
					</a-form-item>
					
					<a-form-item label="申请手机号">
						<a-input v-model="search.mobile" placeholder="请输入申请手机号"></a-input>
					</a-form-item>
					
					
				</a-form>
				
				<a-form layout="inline">
					<a-form-item label="申请身份证号">
						<a-input v-model="search.id_number" placeholder="请输入申请身份证号"></a-input>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="pd30 bg-w " style="min-height: 800px;">
				
				<div class="">
					<div class="wxb-table-gray">
						<a-table rowKey="apply_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
							:data-source="datas" :loading="loading">
							
							
							
							<div class="flex alcenter center" slot="member" slot-scope="member,record">
								<img :src="member.face"  class="member-face" />
								<div class="ml10" style="text-align: left;">
									<div>{{member.nick_name}}｜{{member.mobile}}</div>
								</div>
							</div>
							
							
					
							<template slot="action" slot-scope="record,index">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editRemark(record)">
													<i class="iconfont ft14 iconsee"></i>
													<span class="ml10">添加备注</span>
												</a>
											</a-menu-item>
											
										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>
	
	<div>
		<a-modal title="编辑备注" :width="540" :visible="visible" @cancel="handleCancel">
				<template slot="footer">
					<a-button key="back" @click="handleCancel">
						取消
					</a-button>
					<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
						确认修改
					</a-button>
				</template>
				<div>
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						
						<a-form-model-item required label="备注" >
							 <a-textarea placeholder="请输入备注" v-model="form.remark" :rows="4" :maxLength="220" />
						</a-form-model-item>
							
					</a-form-model>
				</div>
			</a-modal>
		</div>
		
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	
	export default {
		mixins: [listMixin],
		
		data() {
			return {
				loading: false,
				confirmLoading: false,
				visible:false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 20
				},
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					name:'',
					member:'',
					mobile:'',
					id_number: '',
				},
				columns: [
					{title: '申请ID',dataIndex: 'apply_id',align: 'center'},
					{title: '申请人员',dataIndex: 'member',align: 'center',width:250,scopedSlots: {customRender: 'member'}},
					{title: '姓名',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '身份证号',dataIndex: 'id_number',align: 'center',ellipsis: true},
					{title: '备注',dataIndex: 'remark',align: 'center',ellipsis: true},
					{title: '申请时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
				form:{},
				
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getServiceApplyList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
					member:this.search.member,
					mobile:this.search.mobile,
					is_number:this.search.id_number,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			editRemark(record){
				this.form=record;
				this.visible=true;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.visible=false;
			},
			
			/**
			 * 提交数据
			 */
			onSubmit() {
				if (this.confirmLoading == true) return;
				this.confirmLoading = true;
				this.$http.api('admin/saveServiceApplyRemark', {
					apply_id: this.form.apply_id,
					remark: this.form.remark,
					
				}).then(res => {
					this.$message.success('操作成功');
					
					this.confirmLoading = false;
					this.visible=false;
					this.getLists();
				}).catch(res => {
					console.log(res);
					this.confirmLoading = false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			
		}
	}
</script>

<style>
	
</style>
